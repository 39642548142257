import React from "react";
import Header from "../component/Header";
import Content from "../component/Content";
import Footer from "../component/Footer";
import { useLocation } from "react-router-dom";

function DefaultLayout() {
    const location = useLocation();
    return (
        <div>
            {/* <Header /> */}
            <Content />
            {location.pathname !== "/collegeregistration" &&
                <Footer />
            }
        </div>
    );
}

export default DefaultLayout;
