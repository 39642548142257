import React from "react";

const LandingPage = React.lazy(() => import("./views/landingPage"))
const About = React.lazy(() => import("./views/landingPage/About"))
const Blog = React.lazy(() => import("./views/landingPage/Blog"))
const BlogEducation = React.lazy(() => import("./views/landingPage/blog/BlogEducation"))
const BlogLevel = React.lazy(() => import("./views/landingPage/blog/BlogLevel"))
const BlogWays = React.lazy(() => import("./views/landingPage/blog/BlogWays"))
const BlogCollage = React.lazy(() => import("./views/landingPage/blog/BlogCollage"))
const BlogCareer = React.lazy(() => import("./views/landingPage/blog/BlogCareer"))
const CollegeRegistration = React.lazy(() => import("./views/landingPage/FooterPage"))

const routes = [
    { path: "/", name: "LandingPage", element: LandingPage },
    { path: "/about", name: "About", element: About},
    { path: "/blog", name: "Blog", element: Blog},
    { path: "/blog/blogeducation", name: "BlogEducation", element: BlogEducation},
    { path: "/blog/bloglevel", name: "BlogLevel", element: BlogLevel},
    { path: "/blog/blogways", name: "BlogWays", element: BlogWays},
    { path: "/blog/blogcollage", name: "BlogCollage", element: BlogCollage},
    { path: "/blog/blogcareer", name: "BlogCareer", element: BlogCareer},
    {path : "/collegeregistration", name: "CollegeRegistration", element: CollegeRegistration},
]

export default routes;